import { useStaticQuery, graphql } from "gatsby";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

import React, { useEffect, useState } from "react";

import logo from "src/images/assets/estudio94-white.svg";
import hamburgerIcon from "src/images/assets/menu.svg";
import closeIcon from "src/images/assets/cancel.svg";

import "./mobile-header.styl";

const MobileHeader = ({ extraClasses }) => {
  const { languages, originalPath } = useI18next();

  const [open, setOpen] = useState(false);
  const [headerClasses, setHeaderClasses] = useState([
    "mobile_header",
    ...extraClasses,
  ]);
  const [buttonIcon, setButtonIcon] = useState(null);

  const toggleOverlay = () => setOpen(!open);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (open) {
      setHeaderClasses((hc) => [...hc, "mobile_header--show"]);
      setButtonIcon(closeIcon);
    } else {
      setHeaderClasses(["mobile_header", ...extraClasses]);
      setButtonIcon(hamburgerIcon);
    }
  }, [open, extraClasses]);

  return (
    <header className={headerClasses.join(" ")}>
      <div className="mobile_header__inner">
        <Link className="mobile_header__logo" to="/">
          <img src={logo} alt="Estudio 94" />
        </Link>
        <button onClick={toggleOverlay}>
          <img className="mobile_header__toggle" src={buttonIcon} alt="Menu" />
        </button>
      </div>
      <nav className="nav">
        <ul className="nav__inner">
          {site.siteMetadata.menuLinks.map((item) => (
            <li className="nav__item" key={item.name}>
              <Link
                className="nav__link"
                activeClassName="nav__link--active"
                to={item.link}
              >
                <Trans>{item.name}</Trans>
              </Link>
            </li>
          ))}
          <hr className="nav__separator" />
          <li className="nav__item">
            <ul className="nav__languages">
              {languages.map((lng) => (
                <li className="nav__language" key={lng}>
                  <Link
                    className="nav__link"
                    activeClassName="nav__link--active"
                    to={originalPath}
                    language={lng}
                  >
                    {lng}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default MobileHeader;
