import React from "react";
import PropTypes from "prop-types";

import CookieConsent from "../cookie-consent/cookie-consent";
import Footer from "../footer/footer";
import Header from "../header/header";
import MobileHeader from "../mobile-header/mobile-header";

import "../../styles/app.styl";

const Layout = ({ pagename, children }) => {
  var classNames =
    pagename !== null ? ["main_content", pagename] : ["main_content"];

  return (
    <div className="app">
      <CookieConsent />
      <Header></Header>
      <MobileHeader
        extraClasses={[`mobile_header--${pagename}`]}
      ></MobileHeader>
      <div className={classNames.join(" ")}>{children}</div>
      <Footer></Footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
