/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

function SEO({ description, meta, title }) {
  const { language } = useI18next();
  const { t } = useTranslation();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      titleTemplate="%s | Estudio 94"
      meta={[
        {
          name: `charset`,
          content: `utf-8`,
        },
        {
          name: `description`,
          content: t(metaDescription),
        },
        {
          property: `og:title`,
          content: t(title || site.siteMetadata.title),
        },
        {
          property: `og:description`,
          content: t(metaDescription),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: t(site.siteMetadata.title),
        },
        {
          name: `twitter:description`,
          content: t(site.siteMetadata.description),
        },
      ].concat(meta)}
    >
      <title itemProp="name" lang={language}>
        {t(title || site.siteMetadata.title)}
      </title>
    </Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
  description: ``,
  title: `Diseño integral`,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
