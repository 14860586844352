import React, { useEffect, useState } from "react";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

import useCookie from "../useCookie";

import "./cookie-consent.styl";
import cookie from "../../images/assets/cookie.svg";

const CookieConsent = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const [gaCookie, setGACookie] = useCookie("gdpr-google-analytics", "");
  const { navigate } = useI18next();

  const acceptAll = () => {
    setGACookie("true");
  };

  const manageCookies = () => {
    setGACookie("false");
    navigate("/cookies/#manage-cookies");
  };

  useEffect(() => {
    setVisible(gaCookie === "");
  }, [gaCookie]);

  return (
    <>
      {visible && (
        <div className="cookie_consent">
          <div className="cookie_consent__inner">
            <div className="cookie_consent__icon">
              <img src={cookie} />
            </div>
            <div className="cookie_consent__body">
              <h3>
                <Trans>Cookies</Trans>
              </h3>
              <p>
                <Trans>
                  Utilizamos cookies para guardar tus preferencias y analizar de
                  manera anónima como utilizas nuestra página web, para poder
                  mejorar tu experiencia.
                </Trans>
              </p>
              <p>
                <Trans i18nKey="moreInfoCookies">
                  Para más información sobre cookies, visita nuestra{" "}
                  <Link to="/cookies/">Política de cookies</Link>.
                </Trans>
              </p>
              <p>
                <Trans>
                  Para una experiencia óptima, recomendamos que dejes activadas
                  todas las cookies. Si lo prefieres, puedes personalizar tu
                  configuración de cookies haciendo click en 'Gestionar
                  cookies'.
                </Trans>
              </p>
              <div className="cookie_consent__actions">
                <button
                  type="button"
                  className="button button--accept"
                  onClick={acceptAll}
                >
                  <Trans>Aceptar todas</Trans>
                </button>
                <button
                  type="button"
                  className="button button--manage"
                  onClick={manageCookies}
                >
                  <Trans>Gestionar cookies</Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
