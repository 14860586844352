import { useStaticQuery, graphql } from "gatsby";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";

import "./footer.styl";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
            instagram
            secondaryMenu {
              name
              link
            }
          }
        }
      }
    `
  );

  const [form, setForm] = useState({
    name: "",
    email: "",
    telephone: "",
    message: "",
  });

  const [requestState, setRequestState] = useState("idle");

  const handleChange = (event) =>
    setForm({ ...form, [event.target.name]: event.target.value });

  const handleSubmit = (event) => {
    event.preventDefault();

    setRequestState("saving");

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...form }),
    })
      .then(() => setTimeout(() => setRequestState("saved"), 1500))
      .catch((error) => console.log(error));
  };

  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="footer__social">
          <h4>
            <Trans>Conócenos</Trans>
          </h4>
          <ul className="footer__links">
            <li className="footer__item footer__item--about">
              <Link className="footer__link" to="/about/">
                <Trans>¿Quién es Estudio 94?</Trans>
              </Link>
            </li>
            <li className="footer__item footer__item--email">
              <a
                className="footer__link"
                href={`mailto:${site.siteMetadata.email}`}
              >
                {site.siteMetadata.email}
              </a>
            </li>
            <li className="footer__item footer__item--insta">
              <a
                className="footer__link"
                href={`https://instagram.com/${site.siteMetadata.instagram}`}
              >
                @{site.siteMetadata.instagram}
              </a>
            </li>
          </ul>
        </div>
        <form
          className="footer__contact"
          name="contact"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          onSubmit={handleSubmit}
        >
          <h4>
            <Trans>Ponte en contacto</Trans>
          </h4>
          <div className="footer__contact_field --hidden">
            <input name="bot-field" />
          </div>
          <div className="footer__contact_field">
            <label htmlFor="name">
              <Trans>Tu nombre</Trans>*:{" "}
            </label>
            <input
              type="text"
              id="name"
              name="name"
              onChange={handleChange}
              required
            />
          </div>
          <div className="footer__contact_field">
            <label htmlFor="email">
              <Trans>Tu correo electrónico</Trans>*:{" "}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              required
            />
          </div>
          <div className="footer__contact_field">
            <label htmlFor="telephone">
              <Trans>Tu teléfono</Trans>:
            </label>
            <input
              type="text"
              id="telephone"
              name="telephone"
              onChange={handleChange}
            />
          </div>
          <div className="footer__contact_field --textarea">
            <label htmlFor="message">
              <Trans>Mensaje</Trans>*:
            </label>
            <textarea
              id="message"
              name="message"
              onChange={handleChange}
            ></textarea>
          </div>
          <button
            className={`button button--${requestState}`}
            type="submit"
            disabled={requestState !== "idle"}
          >
            {requestState === "idle" && <Trans>Enviar</Trans>}
            {requestState === "saved" && (
              <svg class="animated-check" viewBox="0 0 24 24">
                <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
              </svg>
            )}
          </button>
        </form>
      </div>
      <div className="footer__secondary">
        <div className="footer__inner">
          <ul className="footer__nav">
            {site.siteMetadata.secondaryMenu.map(({ name, link }) => (
              <li className="footer__item" key={name}>
                <Link
                  className="footer__link"
                  activeClassName="footer__link--active"
                  to={link}
                >
                  <Trans>{name}</Trans>
                </Link>
              </li>
            ))}
          </ul>
          <span className="footer__copyright">&copy; 2021, Estudio 94</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
