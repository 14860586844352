import { useState } from "react";

const isBrowser = typeof document !== "undefined";

const getItem = (key) => {
  var value = null;

  if (isBrowser) {
    value = document.cookie.split("; ").reduce((total, current) => {
      const item = current.split("=");
      const storedKey = item[0];
      const storedValue = item[1];

      return key === storedKey ? decodeURIComponent(storedValue) : total;
    }, "");
  }

  return value;
};

const setItem = (key, value) => {
  const now = new Date();
  now.setFullYear(now.getFullYear() + 1);
  const cookieString = `${key}=${value};expires=${now.toUTCString()};SameSite=Strict;path=/`;
  if (isBrowser) {
    document.cookie = cookieString;
  }
};

const useCookie = (key, defaultValue) => {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value) => {
    setCookie(value);
    setItem(key, value);
  };

  return [cookie, updateCookie];
};

export default useCookie;
