import { useStaticQuery, graphql } from "gatsby";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

import React from "react";
import logo from "src/images/assets/estudio94-white.svg";

import "./header.styl";

const Header = () => {
  const { languages, originalPath } = useI18next();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `
  );

  return (
    <header className="header">
      <div className="header__inner">
        <div className="header__left">
          <Link to="/">
            <img className="header__logo" src={logo} alt="Estudio 94" />
          </Link>
        </div>
        <div className="header__right">
          <nav className="nav">
            <ul className="nav__inner">
              {site.siteMetadata.menuLinks.map((item) => (
                <li className="nav__item" key={item.name}>
                  <Link
                    className="nav__link"
                    activeClassName="nav__link--active"
                    to={item.link}
                  >
                    <Trans>{item.name}</Trans>
                  </Link>
                </li>
              ))}
              <li className="nav__item">
                <ul className="nav__languages">
                  {languages.map((lng) => (
                    <li className="nav__language" key={lng}>
                      <Link
                        className="nav__link"
                        activeClassName="nav__link--active"
                        to={originalPath}
                        language={lng}
                      >
                        {lng}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
